import React from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import GeneralPanelSetting from "../General/index";
import ClientPanelSetting from "../CustomerTicket/index";
import KitchenPanelSetting from "../KitchenTicket/index";
import ModeleTicket from "../ModeleTicket";
import Divers from "../Divers";

import "./index.css";

type MenuWrapperType = {
    rowData: any;
    oneShop: boolean;
    setDataIsUpdated: Function;
    isDataUpdated: number | boolean;
};

type TabsMenuType = {
    title: string;
    content: React.ReactNode;
    subMenu?: {
        title: string;
        content: React.ReactNode;
    }[];
};

export function MenuWrapper({
    rowData,
    oneShop,
    setDataIsUpdated,
    isDataUpdated,
}: MenuWrapperType) {
    const { t } = useTranslation();

    const [activeTab, setActiveTab] = React.useState<number>(0);
    const [activeSubTab, setActiveSubTab] = React.useState<number>(0);
    const [isSubMenuCollapsed, setIsSubMenuCollapsed] = React.useState<boolean>(
        false
    );

    const tabs: TabsMenuType[] = [
        {
            title: t("General"),
            content: (
                <GeneralPanelSetting
                    rowData={rowData}
                    oneShop={oneShop}
                    setDataIsUpdated={setDataIsUpdated}
                    isDataUpdated={isDataUpdated}
                />
            ),
        },
        {
            title: t("Customer ticket"),
            content: (
                <ClientPanelSetting
                    rowData={rowData}
                    oneShop={oneShop}
                    setDataIsUpdated={setDataIsUpdated}
                    isDataUpdated={isDataUpdated}
                />
            ),
        },
        {
            title: t("Kitchen ticket"),
            content: (
                <KitchenPanelSetting
                    rowData={rowData}
                    oneShop={oneShop}
                    setDataIsUpdated={setDataIsUpdated}
                    isDataUpdated={isDataUpdated}
                />
            ),
        },
        {
            title: t("Models tickets"),
            content: (
                <ModeleTicket
                    rowData={rowData}
                    oneShop={oneShop}
                    setDataIsUpdated={setDataIsUpdated}
                    isDataUpdated={isDataUpdated}
                />
            ),
        },
        {
            title: t("Various"),
            content: (
                <Divers
                    rowData={rowData}
                    oneShop={oneShop}
                    setDataIsUpdated={setDataIsUpdated}
                    isDataUpdated={isDataUpdated}
                />
            ),
        },
    ];

    function toggleNav(tab: number) {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
        if (isSubMenuCollapsed === true) {
            setIsSubMenuCollapsed(false);
        }
    }

    function toggleSubNav(tab: number) {
        if (activeSubTab !== tab) {
            setActiveSubTab(tab);
        }
    }

    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: "220px 1fr",
                fontSize: "15px",
            }}
        >
            <Nav
                tabs
                className="d-flex flex-column"
                style={{ backgroundColor: "#323333", height: "90.2%" }}
            >
                {tabs.map((element, index) => {
                    return (
                        <NavItem
                            key={index}
                            className={classNames(
                                "pb-1 menu-wrapper-nav-link__clz",
                                {
                                    "active menu-wrapper-items-nav-link-active__clz":
                                        activeTab === index,
                                    "not-allowed-icon__clz":
                                        Object.keys(rowData)[0] !== undefined
                                            ? (rowData.tickets.value === "02" &&
                                                  element.title ===
                                                      t("Customer ticket")) ||
                                              (rowData.tickets.value === "03" &&
                                                  element.title ===
                                                      t("Customer ticket")) ||
                                              (rowData.tickets.value === "04" &&
                                                  element.title ===
                                                      t("Customer ticket"))
                                            : false,
                                    "not-allowed-customer-icon__clz":
                                        Object.keys(rowData)[0] !== undefined
                                            ? (rowData.tickets.value === "01" &&
                                                  element.title ===
                                                      t("Kitchen ticket")) ||
                                              (rowData.tickets.value === "01" &&
                                                  element.title ===
                                                      t("Models tickets"))
                                            : false,
                                }
                            )}
                        >
                            <NavLink
                                className={classNames(
                                    "border-0 menu-wrapper-nav-link__clz",
                                    {
                                        "active menu-wrapper-items-nav-link-active__clz":
                                            activeTab === index,
                                    }
                                )}
                                onClick={() => {
                                    toggleNav(index);

                                    if (element.title === t("Configuration")) {
                                        setIsSubMenuCollapsed(
                                            !isSubMenuCollapsed
                                        );
                                    }
                                }}
                            >
                                <span className="px-2">{element.title}</span>
                            </NavLink>
                            {element.title === t("Configuration") &&
                            isSubMenuCollapsed
                                ? element.subMenu?.map(
                                      (
                                          item: {
                                              title: string;
                                              content: React.ReactNode;
                                          },
                                          subMenuIndex: number
                                      ): JSX.Element => (
                                          <NavLink
                                              disabled={
                                                  item.title ===
                                                  t("Customer account")
                                              }
                                              key={subMenuIndex}
                                              className={classNames(
                                                  "border-0 text-nowrap pl-5 menu-wrapper-nav-link__clz",
                                                  {
                                                      "active options-nav-link-active__clz":
                                                          activeSubTab ===
                                                          subMenuIndex,
                                                  }
                                              )}
                                              onClick={() => {
                                                  toggleSubNav(subMenuIndex);
                                              }}
                                          >
                                              <span className="px-2">
                                                  {item.title}
                                              </span>
                                          </NavLink>
                                      )
                                  )
                                : null}
                        </NavItem>
                    );
                })}
            </Nav>

            <TabContent activeTab={activeTab}>
                {tabs.map((element, index) => {
                    return element.subMenu !== undefined &&
                        isSubMenuCollapsed ? (
                        element.subMenu?.map(({ content }, subMenuIndex) => {
                            return (
                                <TabPane
                                    tabId={index}
                                    className={classNames({
                                        "d-none": subMenuIndex !== activeSubTab,
                                    })}
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                        overflow: "hidden",
                                    }}
                                    key={subMenuIndex}
                                >
                                    {content}
                                </TabPane>
                            );
                        })
                    ) : (
                        <TabPane
                            tabId={index}
                            style={{
                                height: "100%",
                                width: "100%",
                                overflow: "hidden",
                            }}
                            key={index}
                        >
                            {element.content}
                        </TabPane>
                    );
                })}
            </TabContent>
        </div>
    );
}
