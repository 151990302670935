import React from "react";
import { useTranslation } from "react-i18next";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { CardBody } from "reactstrap";
import { PlusIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";

import { store, setIsAddPeripheralClicked, setInAllPrinters } from "./store";
import { store as userStore } from "@components/VerticalLayout/store";

import { UpdateResourceIp, getTypePrinter } from "@helpers/general";

import PrinterConfig from "@pages/Peripherals/Printers/PrinterConfig";
import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import { ConfirmationDeleteMessage } from "@components/Common/ConfirmationDeleteMessage";
import { AlertDeletePeripheralMessage } from "@components/Common/AlertDeletePeripheralMessage";

import DropDownAction from "./DropDownAction";
import PrepheralCardFooter from "./PrepheralCardFooter";
import { PrinterActionColumn } from "./PrinterActionColumn";

import { CustomMainColorIconButton } from "@components/Common/CustomMainColorIconButton";
import { ConfirmationMessageEditPeripheral } from "@components/Common/ConfirmationMessageEditPeripheral";
import { getAuthorizationStates } from "@components/VerticalLayout/StyledSidebarMenu";
import { CustomMainColorButtonWithIcon } from "@components/Common/CustomMainColorButtonWithIcon";
import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";

import "../index.css";

type DeviceAssociateModalType = {
    setIsDeviceAssociateModalOpened: Function;
    oneShop: boolean;
    isDeviceAssociateModalOpened: boolean;
    rowData: any;
    dataIsUpdated: boolean | number;
    setDataIsUpdated: Function;
    selectedRows: any;
};

export default function Printer({
    setIsDeviceAssociateModalOpened,
    isDeviceAssociateModalOpened,
    rowData,
    dataIsUpdated,
    setDataIsUpdated,
    selectedRows,
}: DeviceAssociateModalType) {
    const { t } = useTranslation();
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const { oneShop, userID, franchiseID, shopID } = useSnapshot(userStore);
    const { isAddPeripheralClicked } = useSnapshot(store);

    const [editedData, setEditedData] = React.useState<any>([]);
    const [data, setData] = React.useState<any>([]);
    const [dataIsPrinterUpdated, setDataPrinterIsUpdated] = React.useState<
        boolean | number
    >(false);
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [
        isConfirmationEditRunning,
        setIsConfirmationEditRunning,
    ] = React.useState<boolean>(false);
    const [
        isAddNewPrinterButtonClicked,
        setIsAddNewPrinterButtonClicked,
    ] = React.useState(false);
    const [printerId, setPrinterId] = React.useState<string>("");
    const [isUpdateOperation, setIsUpdateOperation] = React.useState<boolean>(
        false
    );
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [
        isModalDeleteAssociatePeripheralOpened,
        setIsModalDeleteAssociatePeripheralOpened,
    ] = React.useState<boolean>(false);
    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);
    const [isEdited, setIsEdited] = React.useState<boolean>(false);
    const [inputDesignation, setInputDesignation] = React.useState<boolean>(
        false
    );
    const [inputIp, setInputIp] = React.useState<boolean>(false);

    const modify = getAuthorizationStates(
        "5590fe0f-69fc-9ff9-699d-d9a9defd12ab"
    ).Modifie;
    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 999,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <PrinterActionColumn element={e.selectedRow} />
            ),
        },

        {
            indexOFColumn: 0,
            columnName: t("Designation"),
            customJsx: (e: any) => {
                return <div>{e.selectedRow[t("Printer name")]}</div>;
            },
        },
        {
            indexOFColumn: 1,
            columnName: t("Type of configuration"),
            customJsx: (e: any) => {
                return (
                    <div>
                        {!oneShop
                            ? e.selectedRow[t("Shop")] !== undefined ||
                              (e.selectedRow[t("Shop")] !== "" &&
                                  e.selectedRow.subRows) !== undefined
                                ? ""
                                : getTypePrinter(e.selectedRow["type"])
                            : getTypePrinter(e.selectedRow["type"])}
                    </div>
                );
            },
        },
        {
            indexOFColumn: 4,
            columnName: t("Ticket"),
            customJsx: (e: any) => {
                let text: string = "";
                if (Array.isArray(e.selectedRow["tickets"])) {
                    if (e.selectedRow["tickets"].length !== 0) {
                        text = e.selectedRow["tickets"]
                            .map((x: any) => {
                                return t(x.label);
                            })
                            .join(",");
                    }
                } else if (
                    e.selectedRow["tickets"] !== undefined &&
                    e.selectedRow["tickets"].label !== undefined
                ) {
                    text = t(e.selectedRow["tickets"].label);
                }
                return <CustomColumnDisplay text={text} />;
            },
        },
    ];

    function customJsxComponent(e: any) {
        return (
            (e.selectedRow.original[t("Application")] !== "" ||
                e.selectedRow.original[t("Peripheral")] !== "") && (
                <div className="w-100 d-flex justify-content-center">
                    <DropDownAction
                        element={e}
                        setEditedData={setEditedData}
                        setInputIp={setInputIp}
                    />
                </div>
            )
        );
    }
    React.useEffect(() => {
        let dataPrinterShop: any = [];
        if (selectedRows.length !== 0) {
            if (
                selectedRows.filter((elt: any) => elt[t("Application")] !== "")
                    .length === 1
            ) {
                if (data.data?.length > 0) {
                    if (data.data[0][t("Shop")] === undefined) {
                        dataPrinterShop = data.data;
                    } else {
                        dataPrinterShop = data.data.subRows.find(
                            (x: any) =>
                                x.shopId ===
                                selectedRows.filter(
                                    (elt: any) => elt[t("Application")] !== ""
                                )[0].shopId
                        );
                    }
                    for (
                        let i = 0;
                        i <
                        selectedRows.filter(
                            (elt: any) => elt[t("Application")] !== ""
                        )[0].subRows.length;
                        i++
                    ) {
                        const el = selectedRows.filter(
                            (elt: any) => elt[t("Application")] !== ""
                        )[0].subRows[i];
                        if (
                            el.peripheralType === "printer" &&
                            dataPrinterShop.filter(
                                (x: any) => el.peripheralId === x.Id
                            ) !== -1
                        ) {
                            setInAllPrinters(
                                dataPrinterShop.filter(
                                    (x: any) => el.peripheralId === x.Id
                                )[0]
                            );
                        }
                    }
                }
            }
        } else {
            if (data.data?.length > 0) {
                if (data.data[0][t("Shop")] === undefined) {
                    dataPrinterShop = data.data;
                } else {
                    dataPrinterShop = data.data.subRows.find(
                        (x: any) => x.shopId === rowData.shopId
                    );
                }
                if (rowData.subRows !== undefined) {
                    for (let i = 0; i < rowData.subRows.length; i++) {
                        const el = rowData.subRows[i];
                        if (
                            el.peripheralType === "printer" &&
                            dataPrinterShop.filter(
                                (x: any) => el.peripheralId === x.Id
                            ) !== -1
                        ) {
                            setInAllPrinters(
                                dataPrinterShop.filter(
                                    (x: any) => el.peripheralId === x.Id
                                )[0]
                            );
                        }
                    }
                }
            }
        }

        UpdateResourceIp(userID, franchiseID);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    return (
        <React.Fragment>
            <CardBody
                className="p-1"
                style={{
                    maxHeight: isAddPeripheralClicked ? "initial" : "720px",
                }}
            >
                <div
                    style={{
                        display: "grid",
                        minHeight: "650px",
                        gridTemplateColumns: isAddPeripheralClicked
                            ? "2fr 1fr "
                            : "auto",
                        gridColumnGap: "4px",
                        marginBottom: isAddPeripheralClicked ? "70px" : "0px",
                        border: 0,
                    }}
                >
                    <DynamicTable
                        requestHeader={{ "Accept-Language": i18nextLng }}
                        setData={setData}
                        url={`${
                            process.env.REACT_APP_API_V2_URL
                        }/settings/printers?userId=${userID}&shopId=${
                            oneShop
                                ? shopID
                                : selectedRows.length !== 0 &&
                                  selectedRows.filter(
                                      (elt: any) => elt[t("Application")] !== ""
                                  )[0] !== undefined
                                ? selectedRows.filter(
                                      (elt: any) => elt[t("Application")] !== ""
                                  )[0].shopId
                                : rowData.shopId
                        }&franchiseId=${franchiseID}`}
                        customJsxSideFilterButton={
                            isAddPeripheralClicked !== true &&
                            modify === true ? (
                                <div className="d-flex justify-content-start align-items-center">
                                    <span
                                        onClick={() => {
                                            setEditedData([]);
                                            setIsAddPeripheralClicked(
                                                !isAddPeripheralClicked
                                            );
                                        }}
                                        className="afa_btn_addPeripheral"
                                    >
                                        <CustomMainColorButtonWithIcon
                                            icon="PlusIcon"
                                            iconPosition="left"
                                            className="w-100"
                                            style={{ height: "0px" }}
                                            rounded
                                            variant="primary"
                                        >
                                            {t("Add device")}
                                        </CustomMainColorButtonWithIcon>
                                    </span>
                                </div>
                            ) : modify === true ? (
                                <div className="d-flex justify-content-start">
                                    <CustomMainColorIconButton
                                        icon="PlusIcon"
                                        rounded
                                        disabled={
                                            editedData.length === 0
                                                ? false
                                                : true
                                        }
                                        onClick={() => {
                                            if (isEdited === true)
                                                setIsInInitializationModalOpened(
                                                    true
                                                );
                                            else {
                                                setInputDesignation(false);
                                                setInputIp(false);
                                            }
                                        }}
                                    >
                                        <PlusIcon
                                            height={20}
                                            width={20}
                                            fill="white"
                                        />
                                    </CustomMainColorIconButton>
                                </div>
                            ) : null
                        }
                        actionColumn={customJsxComponent}
                        setDataIsUpdated={setDataPrinterIsUpdated}
                        dataIsUpdated={dataIsPrinterUpdated}
                        showGlobalFilter
                        filterActive={filterActive}
                        setLocalFilterActive={setLocalFilterActive}
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        minHeight="520px"
                        maxHeight="520px"
                    />
                    {isAddPeripheralClicked ? (
                        <PrinterConfig
                            setIsAddPrinterClicked={setIsAddPeripheralClicked!}
                            isAddPrinterClicked={isAddPeripheralClicked}
                            setDataIsUpdated={setDataPrinterIsUpdated}
                            editedData={editedData}
                            setEditedData={setEditedData}
                            isAddNewPrinterButtonClicked={
                                isAddNewPrinterButtonClicked
                            }
                            shopIdPrinter={
                                selectedRows.length !== 0 &&
                                selectedRows.filter(
                                    (elt: any) => elt[t("Application")] !== ""
                                )[0] !== undefined
                                    ? selectedRows.filter(
                                          (elt: any) =>
                                              elt[t("Application")] !== ""
                                      )[0].shopId
                                    : rowData.shopId
                            }
                            localShopId={[
                                selectedRows.length !== 0 &&
                                selectedRows.filter(
                                    (elt: any) => elt[t("Application")] !== ""
                                )[0] !== undefined
                                    ? selectedRows.filter(
                                          (elt: any) =>
                                              elt[t("Application")] !== ""
                                      )[0].shopId
                                    : rowData.shopId,
                            ]}
                            inputDesignation={inputDesignation}
                            setInputDesignation={setInputDesignation}
                            inputIp={inputIp}
                            setInputIp={setInputIp}
                            dataTable={data}
                            setIsUpdateOperation={setIsUpdateOperation}
                            isConsult={false}
                            setIsConsult={() => {}}
                            isComingFromAssociate={true}
                        />
                    ) : null}
                </div>
            </CardBody>
            {!isAddPeripheralClicked ? (
                <PrepheralCardFooter
                    dataIsUpdated={dataIsUpdated}
                    setIsEdited={setIsEdited}
                    setIsDeviceAssociateModalOpened={
                        setIsDeviceAssociateModalOpened
                    }
                    isDeviceAssociateModalOpened={isDeviceAssociateModalOpened}
                    rowData={rowData}
                    setDataIsUpdated={setDataIsUpdated}
                    selectedRows={selectedRows}
                />
            ) : null}
            {isModalDeleteAssociatePeripheralOpened === false ? (
                <ConfirmationDeleteMessage
                    isModalOpened={isModalOpened}
                    setIsModalOpened={setIsModalOpened}
                    setDataIsUpdated={setDataPrinterIsUpdated}
                    text={t("Please confirm archivation of this device")}
                    message={t("The device was successfully archived")}
                    apiUrl={`${
                        process.env.REACT_APP_API_V2_URL
                    }/settings/printer?userId=${userID}&shopId=${
                        oneShop ? shopID : rowData.shopId
                    }&franchiseId=${franchiseID}&id=${printerId}&isArchived=true`}
                    setGlobalDataIsUpdated={setDataIsUpdated}
                    data={{ shopId: oneShop ? shopID : rowData.shopId }}
                />
            ) : (
                <AlertDeletePeripheralMessage
                    text={t(
                        "This device is associated with one or more applications! Unable to perform delete action"
                    )}
                    isModalDeleteAssociatePeripheralOpened={
                        isModalDeleteAssociatePeripheralOpened
                    }
                    setIsModalDeleteAssociatePeripheralOpened={
                        setIsModalDeleteAssociatePeripheralOpened
                    }
                />
            )}

            {isInInitializationModalOpened === true ? (
                <ConfirmationInitializationMessage
                    isModalOpened={isInInitializationModalOpened}
                    setIsModalOpened={setIsInInitializationModalOpened}
                    setIsClicked={setIsAddNewPrinterButtonClicked}
                    isClicked={isAddNewPrinterButtonClicked}
                    setIsEdited={setIsEdited}
                />
            ) : null}
            {isConfirmationEditRunning === true ? (
                <ConfirmationMessageEditPeripheral
                    isModalOpened={isConfirmationEditRunning}
                    setIsModalOpened={setIsConfirmationEditRunning}
                    setCancel={setIsAddPeripheralClicked}
                    archive={false}
                />
            ) : null}
        </React.Fragment>
    );
}
