import React from "react";
import { Tooltip } from "@mui/material";
import ReactSwitch from "react-switch";
import { PencilIcon } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { setDefaultSetting, store } from "../store";

export function CustomIcons({ e }: any): JSX.Element {
    const { t } = useTranslation();

    const { defaultSetting } = useSnapshot(store);

    const apiStatus = defaultSetting.paymentMethod.data.find(
        (apiSaleMode: any) => apiSaleMode.id === e.selectedRow.id
    )?.status;

    const handlePencilClick = () => {};

    const handleSwitchChange = (checked: boolean) => {
        const updatedRows = defaultSetting.paymentMethod?.data?.map(
            (row: any) => {
                if (row.Id === e.selectedRow.Id) {
                    return { ...row, status: checked };
                }
                return row;
            }
        );
        setDefaultSetting({
            ...defaultSetting,
            paymentMethod: JSON.parse(JSON.stringify({ data: updatedRows })),
        });
    };
    const filteredSaleSupport = defaultSetting.saleSupport?.data.filter(
        (item: any) => item.status === true
    );
    const supportedSaleModes = filteredSaleSupport.map(
        (support: any) => support.support_de_vente
    );
    const supportVente = e.selectedRow.support_vente || [];
    const isSupported = supportVente.some((mode: any) =>
        supportedSaleModes.includes(mode)
    );

    return (
        <div className="d-flex justify-content-around">
            <Tooltip title={t("Toggle Activation")} arrow>
                <div>
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                        checked={apiStatus}
                        onChange={handleSwitchChange}
                        disabled={!isSupported}
                    />
                </div>
            </Tooltip>

            <div className="ml-3">
                <Tooltip title={t("Edit")} arrow>
                    <div>
                        <PencilIcon
                            onClick={handlePencilClick}
                            height={25}
                            width={25}
                            style={{ cursor: "pointer" }}
                        />
                    </div>
                </Tooltip>
            </div>
        </div>
    );
}
