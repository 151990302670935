import React, { useState } from "react";
import { Tooltip } from "@mui/material";
import ReactSwitch from "react-switch";
import { PencilIcon } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { setDefaultSetting, store } from "../store";

export function CustomIcons({ e }: any): JSX.Element {
    const { t } = useTranslation();

    const [isActive, setIsActive] = useState(e.selectedRow.status);
    const { defaultSetting } = useSnapshot(store);

    const handlePencilClick = () => {};

    const handleSwitchChange = (checked: boolean) => {
        const updatedRows = defaultSetting.currency?.data?.map((row: any) => {
            if (row.Id === e.selectedRow.Id) {
                return { ...row, status: checked };
            }
            return row;
        });
        setDefaultSetting({
            ...defaultSetting,
            currency: JSON.parse(JSON.stringify({ data: updatedRows })),
        });
        const isSelectedRowFavorite = defaultSetting.currency?.data?.find(
            (row: any) => row.Id === e.selectedRow.Id
        )?.isFavorite;

        if (!isSelectedRowFavorite) {
            setIsActive(checked);
        }
    };

    return (
        <div className="d-flex justify-content-around">
            <Tooltip title={t("Toggle Activation")} arrow>
                <div>
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                        checked={isActive}
                        onChange={handleSwitchChange}
                    />
                </div>
            </Tooltip>

            <div className="ml-3">
                <Tooltip title={t("Edit")} arrow>
                    <div>
                        <PencilIcon
                            onClick={handlePencilClick}
                            height={25}
                            width={25}
                            style={{ cursor: "pointer" }}
                        />
                    </div>
                </Tooltip>
            </div>
        </div>
    );
}
